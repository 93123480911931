#section_home_photos{
	padding-top: 150px;
	padding-bottom: 150px;	

	 @include breakpoint(medium down) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include breakpoint(small down) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

	h2{
		  @include heading2;
		  margin-bottom: 40px;
		   @include breakpoint(small down) {
				text-align: center;
				
		   }
	}

	.list_photos{
		
		@include row; 
		

		a{
			display: block; 
			@include grid-column(3); 
			margin-bottom: 30px;

			@include breakpoint(medium down){
				@include grid-column(6); 
			}

			&:last-child:not(:first-child){
				float: left; 
			}

			img{
				display: block; 
				width: 100%; 
				height: auto;
				border-radius: 3px;
			}
		}
	}
}