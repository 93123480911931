#section_bio_interview{
	padding-top: 150px;
	margin-bottom: -30px;

	  @include breakpoint(medium down) {
        padding-top: 100px;
    }

    @include breakpoint(small down) {
        padding-top: 50px;
    }

	h2{
		  @include heading2;
		  margin-bottom: 40px;

		  @include breakpoint(small down) {
				text-align: center; 
		  }
	}

	.text_interview{
		  @include paragraph2;
		  column-count: 3;
		  column-gap: 30px;

		  @include breakpoint(medium down){
		  	column-count: 2;
		  }

		   @include breakpoint(small down){
		  	column-count: 1;
		  }

		  p{
		  	margin-bottom: 30px; 
		  }
	}
}