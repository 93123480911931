#page_term {
    padding-top: 50px;

    padding-bottom: 150px;

    .top_term {
        @include clearfix;
        margin-bottom: 50px;


        @include breakpoint(small down) {
            line-height: 74px;
        }

        .img {
            float: left;
            background-color: $white-three;
            margin-right: 30px;
            width: 170px;
            height: 170px;
            overflow: hidden;
            border-radius: 3px;
            @include breakpoint(small down) {
                //display: block; 
                //margin: 0px auto; 
                //margin-bottom: 30px;
                //float: none; 
                width: 80px;
                height: 80px;
                margin-right: 10px;
            }
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        h1 {
            line-height: 170px;
            display: block;
            floart: left;
            @include sectionTitle;
            @include breakpoint(small down) {
                text-align: left;
                line-height: 25px;  
                font-size: 20px;
                width: calc(100% - 90px);
                display: inline-block;
                vertical-align: middle;
             }
        }
    }
}