.table_musiques {
    width: 100%;
    table-layout: fixed;

    thead {
        
         @include breakpoint(small down) {
            display: none; 
         }

        th {
            border-bottom: solid 1px $white-two;
            font-size: 18px;
            font-weight: bold;
            font-stretch: condensed;
            line-height: 1.17;
            letter-spacing: 1.3px;
            color: $slate-grey;
            text-align: center;
            padding-bottom: 15px;


            &:first-child {
                text-align: left;
                padding-left: 90px;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    tbody {

        @include breakpoint(small down) {
            //display: block; 
        }
        tr {
            @include breakpoint(small down) {
                //display: block; 
            }
            td {
                padding-top: 10px;
                padding-bottom: 10px;
                border-bottom: solid 1px $white-two;
                vertical-align: middle;

                @include breakpoint(small down) {
                    /*display: block; 

                    &[data-label]{

                        &:before{
                            content: attr(data-label); 
                            display: block; 
                            font-weight: bold;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                            font-size: 14px;
                        }
                    }*/

                    &.parolier,
                    &.interprete {
                        display: none;
                    }                    
                }

                &.mobile_toggle {
                    width: 7px;

                    @include breakpoint(medium up) {
                        display: none;
                    }

                    a {
                      

                        span {
                            display: block;
                            height: 7px;
                            width: 7px;
                            border-radius: 50%;
                            background: $white-three;
                            position: relative;

                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                                left: 0;
                                display: block;
                                height: 7px;
                                width: 7px;
                                border-radius: 50%;
                                background: $white-three;
                            }

                            &:before {
                                top: -11px;
                            }

                            &:after {
                                bottom: -11px;
                            }
                        }
                    }
                }


                &:first-child {
                    text-align: left;
                     @include breakpoint(small down) {
                        text-align: center !important;
                     }
                }

                &:last-child {
                    text-align: right !important;

                     @include breakpoint(small down) {
                        text-align: center !important;
                     }
                }

                &:not(.titre) {
                    @include tableTD;
                }

                &.interprete {
                    text-align: right;
                }

                a{
                    color: $slate-grey; 
                    &:hover{
                        color: $blueberry; 
                    }
                }

                &.titre {
                    .main {
                        padding-left: 90px;
                        position: relative;
                        height: 70px;
                        line-height: 70px;
                        display: flex;
                        align-items: center;

                        @include breakpoint(small down) {
                            //display: block; 
                            //height: auto; 
                            //padding-left: 0;
                            padding-left: 75px;
                        }

                        .detail {
                            display: block;

                             @include breakpoint(small down) {
                                    //text-align: center;
                                    //margin-top: 15px;
                             }

                            h3,
                            h4 {
                                @include thumbTitle;
                                @include breakpoint(small down) {
                                    //text-align: center; 
                                }
                            }
                            h4 {
                                font-weight: 300;
                            }
                        }

                        .player {
                            position: absolute;
                            top: 0;
                            left: 0;
                             @include breakpoint(small down) {
                                 //position: relative; 
                                 //margin: 0px auto; 

                            }
                        }
                    }
                }
            }
        }
    }
}



.player {
    width: 60px;
    height: 60px;
    margin-top: 5px;
    vertical-align: middle;
    border-radius: 53px;
    background-color: $white-three;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    cursor: pointer; 
    @include transition(0.3s); 
    overflow: hidden;

    &.playing{
        background: $blueberry;

        .play-pause{
            color: $blueberry;
        }
    }
   


    .play-pause {
        /* This must match the background color behind the button */
        color: $white-three;
        /* This is the color of the button */
        background-color: #fff;

        width: 1.5em;
        height: 1.5em;
        position: relative;
        overflow: hidden;

        /* Draw the 2 pause bars */
        background-image: linear-gradient(to right,
        transparent 40%,
        currentColor 40%,
        currentColor 60%,
        transparent 0);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        /* Hide the central part between the 2 bars */
        transition: background-size .3s;

        &.paused {
            margin-left: 6px;
        }
    }

    .play-pause.paused {
        background-size: 0 100%;
    }

    .play-pause::before,
    .play-pause::after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: currentColor;
        /* Before and after are skewed at 90deg to be invisible */
        transform: skew(90deg);
        transform-origin: top left;
        left: 0;
        

        /* And transitioned to the right angle to transform the square into a
		     triangle */
        transition: transform .3s;
    }

    .play-pause::after {
        transform-origin: bottom left;
    }

    .play-pause.paused::before {
        transform: skew(64deg);
    }

    .play-pause.paused::after {
        transform: skew(117deg);
    }
}