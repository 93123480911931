@mixin heading2 {
  font-family: $fontSecond;
  font-size: 25px;
  font-weight: bold;

  letter-spacing: 1.8px;
  text-align: left;
  color: $blueberry;
}

@mixin paragraph {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.63;
  letter-spacing: 1.1px;
  text-align: left;
  color: $slate-grey;
}

@mixin heading3 {
  font-family: $fontMain;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 1.3px;
  text-align: left;
  color: $charcoal-grey;
}

@mixin subtext {
  font-family: $fontMain;
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.44;
  letter-spacing: 1.3px;
  text-align: left;
  color: $charcoal-grey;
}

@mixin paragraph2 {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.63;
  letter-spacing: 1.1px;
  text-align: left;
  color: $charcoal-grey;
}

@mixin logo {
  font-family: $fontSecond;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.7px;
  text-align: left;
  color: $blueberry;
}

@mixin headercontact {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 0.6px;
  text-align: right;
  color: $charcoal-grey;
}

@mixin sectionTitle {
  font-family: $fontSecond;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: left;
  color: $blueberry;
}

@mixin thumbTitle {
  font-family: $fontMain;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: 1.3px;
  text-align: left;
  color: $charcoal-grey;
}

@mixin thumbText {
  font-family: $fontMain;
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: 1.3px;
  text-align: left;
  color: $charcoal-grey;
}

@mixin backText {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 0.6px;
  text-align: left;
  color: $slate-grey;
}

@mixin tableTH {
  font-family: $fontMain;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 1.3px;
  text-align: center;
  color: $slate-grey;
}

@mixin tableTD {
  font-family: $fontMain;
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  letter-spacing: 1.3px;
  text-align: center;
  color: $slate-grey;
}
