.list-paroliers{
	@include row;
	margin-bottom: -30px !important; ;
	@include clearfix; 
	> li{

		&:last-child{
			float: left !important; 
		}
		margin-bottom: 30px;
		@include grid-column(4); 

		@include breakpoint(large down){
			@include grid-column(6); 
		}

		@include breakpoint(small down){
			@include grid-column(12); 
		}
		.bg{
			position: relative; 
			min-height: 70px; 
			padding-left: 100px; 

			display: flex; 
			align-items: center; 

			&:hover{
				.text{
					h4{
						color: $blueberry; 
					}
				}

				.img{
					img{
						 @include scale(1.1) 
					}
				}
			}			

			.img{
				  background-color: $white-three;
				  position: absolute; 
				  border-radius: 3px; 
				  overflow: hidden; 
				  -webkit-mask-image: -webkit-radial-gradient(white, black);
				  top: 0; 
				  left: 0; 
				  width: 70px; 
				  height: 70px;

				  img{
				  	display: block; 
					width: 100%; 
					height: auto;
					 transition: all 0.3s; 
					 @include scale(1) 
				  }
			}

			.text{
				display: block; 
				margin-top: -3px;
			}


			h4{
				 @include thumbTitle;
				 transition: all 0.3s; 
			}
		}
	}
}