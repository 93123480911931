html, body{
	height: 100%;
}


body{
	background: #fff; 
	font-family: $fontMain; 

	*{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

#skip-link{
	display: none; 
}

* {
    box-sizing: border-box;
}

.element-invisible{
display: none; 

}

.node.node-musique.node-teaser{
	display: none; 
}