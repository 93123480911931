#page_wrapper{
	
	position: relative; 
	min-height: 100%; 
}

#page{
	padding-top: 60px; 
	//padding-bottom: 150px;
	min-height: calc(100vh - 150px);

	 @include breakpoint(small down) {
		padding-bottom: 0;
	 }
}


body.adminimal-menu {
	padding-top: 29px;
}