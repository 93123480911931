#section_bio_intro {
    padding-bottom: 150px;
    padding-top: 150px;

    @include breakpoint(medium down) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include breakpoint(small down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.section_default_page {
    @include clearfix;

    h2 {
        @include heading2;
        margin-bottom: 30px;

        @include breakpoint(small down) {
            text-align: center;
        }
    }

    .text {
        @include paragraph;
        p {
            @include paragraph;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
            @include breakpoint(small down) {
                text-align: center;
            }
        }
    }

    .img {
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0px auto;
        }

        @include breakpoint(small down) {
            margin-top: 40px;
        }
    }

    .row {
        @include breakpoint(large up) {
            @include row;

            .content {
                @include grid-column(4);
            }
            .img {
                @include grid-column(3);
            }
        }
        @include breakpoint(medium only) {
            @include row;

            .content {
                @include grid-column(6);
                margin: 0 !important;
            }
            .img {
                @include grid-column(6);
                margin: 0 !important;
            }
        }
    }

    @include breakpoint(medium up) {

        &:nth-child(2n+1) {
            .row {
                .content {
                    float: right !important;
                    margin-right: 16.66667%;
                }

                .img {
                    float: left !important;
                    @include grid-column-offset(2);
                }
            }
        }

        &:nth-child(2n) {
            .row {
                .img {
                    @include grid-column-offset(1);
                    @include grid-column-end;
                }

                .content {
                    float: left !important;
                    @include grid-column-offset(2);
                }
            }
        }
    }

    &:not(:last-child) {
        margin-bottom: 150px;

          @include breakpoint(medium down) {
			margin-bottom: 100px;
        }

        @include breakpoint(small down) {
			margin-bottom: 50px;
        }
    }

    @include breakpoint(medium up) {

        .content,
        .img {
            line-height: 370px;

            .inner {
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
            }
        }
    }
}