#footer {
    height: 150px;
    border-top: solid 0.4px $white-two;
    //position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    @include breakpoint(small down) {
        height: auto;
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @include breakpoint(medium up) {
        .left {
            float: left;
        }
        .right {
            float: right;
        }
    }

    #logo_footer {
        @include logo;
        line-height: 150px;
        height: 150px;

        @include breakpoint(small down) {
            line-height: initial;
            height: auto;
            text-align: center;
            margin-bottom: 40px;
        }
    }

    #footer_nav {
        ul {
            @include breakpoint(medium up) {
                float: left;
            }
             @include breakpoint(small down) {
				&:not(:last-child){
					margin-bottom: 15px;
				}
            }
        }
        li {

            @include breakpoint(medium up) {
                width: 102px;
                text-align: right;
                float: left;
            }

            @include breakpoint(small down) {
				&:not(:last-child){
					margin-bottom: 15px;
				}
            }

            a {
                display: block;
                font-size: 16px;
                font-weight: 300;
                font-stretch: condensed;
                letter-spacing: 0.6px;
                text-align: right;
                color: $charcoal-grey;
                height: 150px;
                line-height: 150px;
                @include breakpoint(small down) {
                    line-height: initial;
                    height: auto;
                    text-align: center;
                }
                &:hover {
                    color: $blueberry;
                }
            }
        }
    }
}