#user-login{
	background: $white; 
	max-width: 500px; 
	margin: 0px auto; 
	margin-top: 150px;
	margin-bottom: 50px;
	padding: 50px; 

	.description{
		display: none; 
	}

	label{
		display: block; 
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	.form-item{
		margin-bottom: 20px;
	}

	input[type=text], input[type=password]{
		width: 100%; 
		display: block;
		margin: 0 auto;
		border-radius: 3px;
		border: solid 1px $slate-grey;
		height: 45px;
		line-height: 45px;
		font-size: 16px;
		font-weight: 300;
		font-stretch: condensed;
		letter-spacing: 1.1px;
		color: $slate-grey;
		background: transparent; 
		padding-left: 20px;
		outline: none; 
	}

	input[type=submit]{
		width: 100%; 
		display: block;
		margin: 0 auto;
		border-radius: 3px;
		border: solid 1px $blueberry;
		height: 45px;
		background: $blueberry; 
		line-height: 45px;
		font-size: 16px;
		font-weight: 300;
		font-stretch: condensed;
		letter-spacing: 1.1px;
		color: #fff;
		ourline: none; 
		cursor: pointer; 

		&:hover{
			background: #fff; 
			color: $blueberry; 
		}
	}
}