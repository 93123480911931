#section_bio_prix{
	background-color: $white;
	padding-top: 150px;
	padding-bottom: 120px; 


    @include breakpoint(medium down) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include breakpoint(small down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }


	h2{
		  @include heading2;
		  margin-bottom: 40px;

		   @include breakpoint(small down) {
			text-align: center;
			
		   }
	}

	.list_prix{
		@include row; 

		> li{
			margin-bottom: 30px;

			
			&:last-child{
				float: left !important; 
			}
			
			@include breakpoint(large up){
			@include grid-column(4); 
			&:nth-child(3n+1){
				clear: both; 
			}
			}

			@include breakpoint(medium down){
				@include grid-column(6); 
				&:nth-child(2n+1){
					clear: both; 
				}
			}
			@include breakpoint(small down){
				@include grid-column(12); 
			}


			

			h3{
				  @include subtext;
				  font-weight: 700; 
				  margin-bottom: 10px;
			}

			p{
				  @include subtext;
			}
		}
	}
}