#top_filtres {
    border-bottom: solid 1px $white-two;
    @include clearfix;
    padding-bottom: 30px;
    margin-bottom: 35px;
    padding-top: 30px;

    .left {
        float: left;
        @include clearfix;
        padding-top: 15px;

        @include breakpoint(medium down) {
            padding-top: 0;
            float: none;
            margin-bottom: 30px;
        }

        h3 {
            font-size: 16px;
            font-stretch: condensed;
            letter-spacing: 0.6px;
            text-align: left;
            color: $slate-grey;
            float: left;
            width: 102px;
            margin-right: 100px;

            @include breakpoint(large only) {
                margin-right: 50px;
            }

            @include breakpoint(medium down) {
                margin-right: 0;
                text-align: center;
                margin-bottom: 40px;
                float: none;
                display: block;
                width: 100%;
                text-transform: uppercase;
            }
        }

        ul {
            float: left;
              @include breakpoint(medium down) {
                    float: none; 
                    @include clearfix; 
              }

            >li {
                float: left;
                width: 102px;
                height: 30px;
                opacity: 0.5;
                font-size: 16px;
                font-stretch: condensed;
                letter-spacing: 0.6px;
                text-align: center;
                color: $charcoal-grey;
                cursor: pointer;
                position: relative;
                transition: all 0.5s;

                @include breakpoint(large only) {
                    width: 90px;
                }

                @include breakpoint(medium down) {
                    width: 25%;
                    margin-right: 0 !important; 
                    padding-bottom: 30px; 
                }

                @media screen and (max-width: 400px){
                    font-size: 13px;
                }

                

                &:not(:last-child) {
                    margin-right: 25px;
                }

                &:after {
                    content: "";
                    height: 5px;
                    background-color: $charcoal-grey;
                    width: 0%;
                    position: absolute;
                    bottom: -31px;
                    left: 50%;
                    transition: all 0.5s;
                    @include breakpoint(medium down) {

                        bottom: 0; 
                        height: 3px;
                    }
                }

                &:hover {
                    opacity: 1;
                }

                &.active {
                    opacity: 1;

                    &:after {
                        left: 0;
                        width: 100%;
                        ;
                    }
                }
            }
        }
    }

    .right {
        float: right;

        @include breakpoint(medium down) {
            float: none;
        }

        .search_box {
            width: 270px;
            height: 45px;
            position: relative;

             @include breakpoint(medium down) {
                margin: 0px auto; 
             }

             @include breakpoint(small down) {
                width: 100%; 
             }

            input {
                border-radius: 3px;
                border: solid 1px $slate-grey;
                display: block;
                width: 100%;
                height: 45px;
                padding-left: 20px;
                font-size: 16px;
                font-weight: 300;
                font-stretch: condensed;
                letter-spacing: 1.1px;
                text-align: left;
                color: $charcoal-grey;
                outline: none;


                @inlude placeholder {
                    color: $slate-grey;
                }

                &:focus {
                    @inlude placeholder {
                        color: rgba($slate-grey, 0.5);
                    }
                }
            }
        }
    }
}