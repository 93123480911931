$grid-row-width: 1170px;
$columns: 12;
$gutters: px;


/*$breakpoints: (
  small: 0px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
*/
@mixin container {
  	@include grid-row($columns, $gutters, $grid-row-width);

	@include breakpoint(large down) {
	max-width: 900px; 
	}	

	@include breakpoint(medium down) {
		max-width: 600px; 
	}

	@include breakpoint(small down) {
		max-width: 100%; 
		padding: 0px 30px; 
	}

	@media screen and (max-width: 400px) {
		padding: 0px 15px; 
	}
}

.container{
	@include container; 
}


@mixin row{
	@include clearfix; 
	margin: 0px -15px; 
}