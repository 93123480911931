#section_home_biographie {
    padding-top: 140px;
    padding-bottom: 140px;

  

    @include breakpoint(medium down) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include breakpoint(small down) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include clearfix;


    .row {

        @include row;
    }

    .left {
        @include breakpoint(large up) {
            @include grid-column(5);
        }

        @include breakpoint(medium down) {
            margin-bottom: 30px;
        }
    }

    .right {

        @include breakpoint(large up) {
            @include grid-column(7);
            padding-right: 54px !important;
        }

        @include breakpoint(xlarge up) {
            padding-right: 110px !important;
        }
    }

    .img {
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0px auto;
            border-radius: 50%;
        }
    }



    h2 {
        @include heading2;
        margin-bottom: 30px;
        @include breakpoint(medium down) {
            text-align: center;
        }
    }
    .text {
        @include paragraph;
        line-height: 1.75;
        @include breakpoint(medium down) {
            text-align: center;
        }

        p{
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
    }

    .button_container {

        a{
            display: block;
            margin-top: 30px;
            width: 212px;
            height: 45px;
            border-radius: 3px;
            border: solid 1px $blueberry;
            color: $blueberry;
            text-align: center;
            line-height: 42px;
            font-size: 16px;
            font-weight: 300;
            font-stretch: condensed;
            letter-spacing: 1.1px;
            text-align: center;
            transition: all 0.3s; 
            @include breakpoint(medium down) {
                margin-left: auto;
                margin-right: auto;
            }

            &:hover {
                background: $blueberry;
                color: #fff;
            }
        }
    }
}