#section_home_intro {
    padding-top: 195px;
    padding-bottom: 195px;
    background-color: $blueberry;
    text-align: center;

      background-size: cover;
    background-position: center center;

     @include breakpoint(medium down) {
        padding-top: 130px;
        padding-bottom: 130px;
    }

      @include breakpoint(small down) {
        padding-top: 70px;
        padding-bottom:70px;
    }
    

    h1 {
        font-family: $fontSecond;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 1.3px;
        color: #ffffff;
        margin-bottom: 15px;

          @include breakpoint(small down) {
                font-size: 30px; 
          }
    }

    h2 {
        font-size: 18px;
        font-weight: 300;
        font-stretch: condensed;
        letter-spacing: 1.3px;
        color: #ffffff;
        height: 60px;
    }

    .button_container {
        a {
        	display: block; 
            margin: 0px auto;
            border-radius: 3px;
            border: solid 1px #ffffff;
            width: 210px;
            max-width: 100%;
            height: 45px;
            line-height: 42px;
            font-size: 16px;
            font-weight: 300;
            font-stretch: condensed;
            letter-spacing: 1.1px;
            text-align: center;
            color: #ffffff;
             transition: all 0.3s; 

            &:hover{
				background: #fff; 
				color:  $blueberry;
            }
        }
    }
}