.section_search{
	
	padding-bottom: 40px;
	

	&:not(:last-child){
		margin-bottom: 40px; 
		border-bottom: solid 1px $white-two;
	}

	h3{
		 @include sectionTitle;
		 margin-bottom: 45px; 
		 text-transform: uppercase; 

		 @include breakpoint(small down) {
			text-align: center;
			
		 }

		 &.no_uppercase{
		 	text-transform: none; 
		 }
	}
}

.musiques_content{
	padding-bottom: 100px; 
}