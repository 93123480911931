.list-succes{
	@include row;
	@include clearfix; 

	> li{
		margin-bottom: 30px;

		&:last-child{
			float: left !important; 
		}
	
		@include grid-column(2); 

		@include breakpoint(large down){
			@include grid-column(4); 
			margin-bottom: 30px;
		}

		@include breakpoint(medium down){
			@include grid-column(4); 
		}

		@include breakpoint(small down){
			@include grid-column(6); 
		}
		@media screen and (max-width: 400px) {
			@include grid-column(12); 
		}

		.bg{
			display: block; 
			max-width: 170px; 
			margin: 0px auto; 
			min-height: 230px; 

			.img{
				width: 100%; 
				overflow: hidden; 
				height: 170px;  
				 border-radius: 3px;
  				background-color: $white-three;
  				margin-bottom: 16px;
				-webkit-mask-image: -webkit-radial-gradient(white, black);

  				img{
					display: block; 
					width: 100%; 
					height: auto;
					@include transition(0.3s); 
					@include scale(1)
  				}
			}

			&:hover{
				.img{
					img{
						@include scale(1.1); 
					}
				}
			}

			h3, h4{
				font-size: 16px;
				font-weight: bold;
				font-stretch: condensed;
				line-height: 1.31;
				letter-spacing: 1.1px;
				text-align: left;
				color: $charcoal-grey;
				overflow: hidden; 
				white-space: nowrap; 
				text-overflow: ellipsis; 
			}

			h4{
				font-weight: 300; 
				margin-top: 3px;
			}
		}
	}
}