#page_contact {
    padding-top: 150px;
    padding-bottom: 150px;

    @include breakpoint(medium down) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

      @include breakpoint(small down) {
        padding-top: 50px;
        padding-bottom:50px;
    }

    @include breakpoint(medium up) {

        .content {
            float: left !important;

            .text_container {
                height: 370px;
                display: flex;
                align-items: center;

                .text {
                    max-width: 370px;
                }
            }
        }


        .row {
            @include row;
            @include clearfix;
        }
    }

    @include breakpoint(xlarge up) {

        .content {
            @include grid-column(4);
            @include grid-column-offset(1);
        }

        .img {
            @include grid-column(3);
            @include grid-column-offset(2);
        }
    }

    @include breakpoint(large only) {

        .content {
            @include grid-column(5);
            @include grid-column-offset(1);
        }

        .img {
            @include grid-column(4);
            @include grid-column-offset(1);
        }
    }
    @include breakpoint(medium only) {

        .content {
            @include grid-column(6);
        }

        .img {
            @include grid-column(6);
        }
    }

    .img {
        img {
            display: block;
            max-width: 100%;
            height: auto;
            border-radius: 3px;
            margin: 0px auto;
        }
    }

    .content {
        @include breakpoint(small down) {
            margin-top: 40px;
            text-align: center;
        }
        h1 {
            @include heading2;
            margin-bottom: 40px;
            @include breakpoint(small down) {
                text-align: center;
            }
        }

        p {
            @include paragraph;
            margin-bottom: 25px;
            @include breakpoint(small down) {
                text-align: center;
            }
        }

        a.mailto {
            display: inline-block;
            padding-left: 30px;
            padding-right: 30px;
            height: 45px;
            line-height: 42px;
            border-radius: 3px;
            border: solid 1px $blueberry;
            font-size: 16px;
            font-weight: 300;
            font-stretch: condensed;
            letter-spacing: 1.1px;
            text-align: center;
            color: $blueberry;
            @include transition(0.3s);

            &:hover {
                background: $blueberry;
                color: #fff;
            }
        }
    }
}