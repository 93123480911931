.dialog--open{
	z-index: 5555;
}

.dialog__content{
	margin: 0 15px;
	padding: 0;
	max-height: 90%; 
	overflow: auto;
	max-width: 90vw;
	width: 900px;
	border: 1px solid #d5d5d5;

	@include breakpoint(medium down) {	
		max-height: 100%;
		overflow: auto;
		max-width: 100vw;
	}

	h2{
		
		
	}

	.content{
		
	}

	.scrollable{
		height: 100%; 
		overflow: hidden;
	}

	.dialog_top {
		line-height: 52px;
		text-align: left;
		padding-left: 25px;
		border-bottom: 1px solid #d5d5d5;

		.closeDialog.arrow {
			display: inline-block;
			vertical-align: middle;
			width: 18px;
			height: 14px;
			background: url(../img/popup_arrow.png) center no-repeat;
			background-size: 100%;
		}
	}

	.dialog_bg{
		max-height: 100%; 
		padding: 0 20px;
		text-align: left;

		> div {			
			padding: 15px 0;

			&:not(:last-child) {
				border-bottom: 1px solid #d5d5d5;
			}

			h3 {
				font-size: 18px;
				line-height: 21px;
				color: rgb(51, 54, 72);
				font-weight: 700;
			}

			h4 {				
				font-size: 18px;
				line-height: 21px;
				color: rgb(51, 54, 72);

				a {
					display: inline-block !important;
				}
			}

			&:not(:first-child) h3 {
				color: rgb(91,94,109);
			}
		}
	}
}


#dialogMessage{
	.dialog__content{
		
		.dialog_bg{
			overflow: hidden;
		}
	}
}


#dialogEtudeDownloadList{
	
	.dialog__content{
		padding: 70px 30px 30px 30px;
		width: 100%; 
		text-align: left;
		height: 100%; 
	}
}