.bouton_retour {
	padding-left: 62px;
	line-height: 45px;
	position: relative;
	display: inline-block;
	margin-bottom: 50px;
	font-size: 16px;
	font-weight: 700;
	color: rgb(91,94,109);
	@include transition(0.3s);

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		background: url(../img/back_arrow.png) center no-repeat #333548;
		background-size: 18px auto;
	}

	&:hover {
		color: $blueberry;

		&:before {
			background-color: $blueberry;
		}
	}
}