.second_filter{
	@include clearfix; 
	margin-bottom: 40px; 

	@include breakpoint(small down) {
		display: none; 	
	}

	.left{
		@include breakpoint(medium up) {
		float: left; 
		}

		a{
			font-size: 16px;
			font-weight: bold;
			font-stretch: condensed;
			letter-spacing: 1px;
			text-align: left;
			color: $charcoal-grey;
			opacity: 0.5; 
			transition: all 0.5s; 
			cursor: pointer; 
			&.active{
				opacity: 1; 
			}
		}
	}

	.right{
		@include breakpoint(medium up) {
			float: right; 
		}
		  user-select: none;

		ul{
			  user-select: none;
			> li{
				float: left; 

				font-size: 16px;
				font-weight: 300;
				font-stretch: condensed;
				letter-spacing: 6.2px;
				text-align: right;
				color: $charcoal-grey;
				opacity: 0.5;
				text-transform: uppercase;
				cursor: pointer;  
				transition: all 0.5s; 
				  user-select: none !important;

				  &:hover{
				  	color: $blueberry; 
				  }

				&.active{
					opacity: 1; 
				}

				&:not(:last-child){
					margin-right: 10px; 
				}

			}

			&.list-years{
				> li{
				 letter-spacing: 1px;
				}
			}
		}
	}
}