#header {
    position: fixed;
    width: 100%;
    border-top: solid 0.4px $white-two;
    border-bottom: solid 0.4px $white-two;
    top: 0;
    left: 0;
    height: 60px;
    background: #fff;
    z-index: 555;

    .left {
        position: absolute;
        top: 0;
        left: 0;
    }
    .right {
        position: absolute;
        top: 0;
        right: 0;
    }

    .middle {
        text-align: center;
    }

    #logo {
        @include logo;
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
    }

    #main_nav {
        ul {
            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 40px;
                }
                a {
                    font-size: 16px;
                    font-weight: 300;
                    font-stretch: condensed;
                    letter-spacing: 0.6px;
                    text-align: center;
                    color: $charcoal-grey;
                    height: 60px;
                    line-height: 60px;
                    display: block;
                    &:hover {
                        color: $blueberry;
                    }
                }
            }
        }
    }

    #second_nav {
        padding-right: 20px;
        ul {
            float: left;
            &:not(:first-child) {
                margin-left: 20px;
            }
            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 20px;
                }
                a {
                    @include headercontact;
                    height: 60px;
                    line-height: 60px;
                    display: block;
                    &:hover {
                        color: $blueberry;
                    }
                }
            }
        }
    }


    .hamburger-menu-wrapper {
        display: none;
    }

    @include breakpoint(small down) {
        .hamburger-menu-wrapper {
            display: block;
        }

        #responsive_menu {
            display: none;

            position: fixed; 
            background: #fff; 
            width: 100%;
            top: 60px; 
            left: 0;
            right: 0;

            .right{
                position: relative; 

                ul{
                    float: none !important; 
                }
            }

            #second_nav, 
            #main_nav{
                padding: 0;
                ul{
                    li{
                        display: block; 
                        margin: 0px !important; 

                        a{
                           text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.adminimal-menu {
    #header {
        top: 29px;
    }
}