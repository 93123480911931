#section_home_succes, #section_home_moins_connues {
    padding-top: 150px;
    padding-bottom: 140px;
    background: $white;

    h2 {
        @include heading2;
        margin-bottom: 40px;

        @include breakpoint(small down) {
            text-align: center;
        }
    }

    @include breakpoint(medium down) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include breakpoint(small down) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}